
import { defineComponent, reactive, ref } from "vue";
import { visitorService } from "@/service";

export default defineComponent({
  setup() {
    const visitorList = ref([]);
    const pageReactive = reactive({
      page: 1,
      pagesize: 10
    });

    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);

    const onLoad = async () => {
      if (refreshing.value) {
        visitorList.value = [];
        refreshing.value = false;
      }

      try {
        const { data } = await visitorService.visitorLists({
          type: "2",
          state: "0",
          ...pageReactive
        });
        visitorList.value = visitorList.value.concat(data.data);
        pageReactive.page += 1;
        if (visitorList.value.length >= data.total) {
          finished.value = true;
        }
      } catch (error) {
        finished.value = true;
      } finally {
        loading.value = false;
      }
    };

    const onRefresh = () => {
      finished.value = false;
      loading.value = true;
      pageReactive.page = 1;
      onLoad();
    };

    return {
      refreshing,
      loading,
      onRefresh,
      onLoad,
      finished,
      visitorList
    };
  }
});
